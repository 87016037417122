@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;700&display=swap');

body {
  margin: 0;
}

.App {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  min-height: 100px;
  background: white;
  border-bottom: 1px solid #e9e9e9;
  box-shadow: 0 0px 10px #dfdfdf;
  position: relative;
}

.header__container {
  display: flex;
  flex-grow: 1;
  margin: 0px 140px;
  justify-content: space-between;
}

.header__block {
  display: flex;
  align-items: center;
}

.header__menu {
  cursor: pointer;
}

.header__logo {
  cursor: pointer;
  margin-left: 80px;
  padding-top: 4px;
}

.header__calendar {
  cursor: pointer;
  display: flex;
  margin-right: 60px;
}

.header__calendar__data {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;

  line-height: 22px;
  padding-top: 2px;
  margin-right: 20px;
}

.header__notification {
  cursor: pointer;
  padding-top: 4px;
}

.leva-c-kWgxhW-bCBHqk-fill-false {
  position: absolute !important;
  top: auto !important;
  left: 10px;
  bottom: 10px;
}

.view3d {
  position: relative;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.view3d__title {
  position: absolute;
  left: 140px;
  top: 80px;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.2s;
}

.view3d__title__name {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
}

.view3d__title__text {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #f99723;
}

.view3d__viewport {
  flex-grow: 1;
}

.view3d__panel {
  background-color: #f9f9f9;
  box-shadow: 0 0px 10px #dfdfdf;
  z-index: 1;
}

.view3d__panel__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.view3d__panel__container {
  font-family: 'Manrope';
  box-sizing: border-box;
  padding: 30px 20px 0px 20px;
  height: 100%;
  border-left: 1px solid #e9e9e9;
  min-width: 430px;
}

.view3d__panel__spin {
  height: 100%;
}

.ant-spin {
  max-height: none !important;
}

.ant-spin-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.view3d__panel__title {
  display: flex;
  align-items: center;
}

.view3d__panel__title__name {
  font-family: 'Manrope';
  font-weight: 700;
  font-size: 24px;
  color: #233645;
}

.view3d__panel__title__info {
  cursor: pointer;
  padding-top: 6px;
  padding-left: 12px;
}

.view3d__panel__indicators {
  display: flex;
}

.view3d__panel__red_indicator {
  font-family: 'Manrope';
  display: flex;
  align-items: baseline;
  padding-right: 20px;
  font-size: 16px;
}

.view3d__panel__red_indicator img {
  padding-right: 10px;
}

.view3d__panel__yellow_indicator {
  font-family: 'Manrope';
  display: flex;
  align-items: baseline;
  padding-right: 20px;
  font-size: 16px;
}

.view3d__panel__yellow_indicator img {
  padding-right: 10px;
}

.view3d__panel__green_indicator {
  font-family: 'Manrope';
  display: flex;
  align-items: baseline;
  font-size: 16px;
}

.view3d__panel__green_indicator img {
  padding-right: 10px;
}

.view3d__panel__machines {
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  padding-bottom: 20px;
  padding-right: 8px;
}

.view3d__panel__machine__container {
  cursor: pointer;
  box-sizing: border-box;
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  transition: all 1s;
  transition-delay: 6s;
}

.view3d__panel__machine__header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
}

.view3d__panel__machine__header__left {
  font-family: 'Manrope';
  display: flex;
  align-items: center;
}

.view3d__panel__machine__header__status {
  width: 92px;
  height: 24px;
  border-radius: 5px;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  padding-top: 4px;
  box-sizing: border-box;
}

.machine__status__active {
  background: rgba(54, 191, 118, 0.1);
  color: #36bf76;
}

.machine__status__service {
  background: rgba(244, 169, 0, 0.2);
  color: #f4a900;
}

.machine__status__repaire {
  background: rgba(244, 60, 60, 0.1);
  color: #f43c3c;
}

.view3d__panel__machine__header__data {
  margin-left: 10px;
  font-size: 14px;
  color: #b8b2b2;
}

.view3d__panel__machine__text {
  font-family: 'Manrope';
  font-size: 14px;
  color: #233645;
  line-height: 130%;
}

.view3d__panel__machine__code {
  padding-top: 4px;
  font-family: 'Manrope';
  font-size: 14px;
  color: #b8b2b2;
  line-height: 130%;
}

.view3d__canvas {
  position: absolute !important;
}

.three_stats {
  display: flex;
  position: absolute !important;
}

.three_stats canvas:nth-child(1) {
  display: block !important;
}

.three_stats canvas:nth-child(2) {
  display: block !important;
}

.three_stats canvas:nth-child(3) {
  display: block !important;
}
